export const todo = [
    {
        item: 'Learn about reducers',
        completed: false,
        id: 3892987589
    }
];

export const todoReducer = (state, action)  => {
    console.log(state, action)
    switch(action.type){
        case 'ADD_ITEM':
            console.log(state)
            return [
                ...state,
                {
                    item: action.payload,
                    completed: false,
                    id: Date.now(),
                }
            ];
        case 'CROSS_ITEMS':
            return state.map(todo => {
                if (todo.id === action.payload){
                    return {
                        ...todo,
                        completed: !todo.completed
                    }
                }
                return todo;
            });
        case 'CLEAR_COMPLETED':
            return state.filter(todo => !todo.completed)
        
        default:
        return state;
    }
    
}; 
